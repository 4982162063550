// import React from "react";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import { Link } from "react-scroll";
// import styled from "styled-components";
// import { colors } from "../../utils/colors";
// import ServiceCard from "../ServiceCard";
// import LayoutButton from "../LayoutButton";

// const AboutUsContainer = styled(Box)``;

// export default function AboutUs() {
//   return (
//     <AboutUsContainer id="about">
//       <Container>
//         <Box
//           sx={{
//             // paddingTop: { lg: 9, md: 7, sm: 5, xs: 5 },
//             marginTop: { lg: "2%" },
//             marginBottom: { lg: "5%" },
//           }}
//         >
//           <Typography
//             variant="h4"
//             sx={{
//               textAlign: "center",
//               fontSize: {
//                 lg: "2.4rem",
//                 md: "1.7rem",
//                 sm: "1.5rem",
//                 xs: "1.3rem",
//               },
//               fontWeight: 600,
//               color: colors.fontColor,
//               marginBottom: { lg: "3%" },
//             }}
//           >
//             About Us
//           </Typography>

//           <Typography
//             variant="subtitle1"
//             sx={{
//               //   fontSize: { lg: "1.2rem", md: "1.2rem" },
//               color: colors.fontGray,
//               marginBottom: 4,
//               marginTop: 2,
//               textAlign: "justify",
//             }}
//             gutterBottom
//           >
//             At Webtimate, we're dedicated to revolutionizing your frontend
//             development experience. We understand the challenges faced by
//             businesses in today's competitive landscape, from talent scarcity to
//             the need for rapid scaling. That's why we're here to streamline your
//             frontend development with immediate onboarding and zero hassle.
//           </Typography>
//           <Typography
//             variant="subtitle1"
//             sx={{
//               //   fontSize: { lg: "1.2rem", md: "1.2rem" },
//               color: colors.fontGray,
//               marginBottom: 4,
//               marginTop: 2,
//               textAlign: "justify",
//             }}
//             gutterBottom
//           >
//             We Supercharge Your Frontend development with Top-tier Expertise Our
//             team of dedicated developers is your ticket to offloading frontend
//             development with absolute confidence. Whether it's ReactJS or React
//             Native, our skilled professionals excel at creating secure,
//             scalable, and dynamic web and mobile apps that perfectly align with
//             your vision. With Webtimate handling the frontend, you can focus on
//             what you do best.
//           </Typography>
//         </Box>
//       </Container>
//     </AboutUsContainer>
//   );
// }

import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { colors } from "../../utils/colors";

const AboutUsContainer = styled(Box)`
  background-color: ${colors.background || "#f5f5f5"};
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -5%;
    width: 110%;
    height: 100%;
    background: linear-gradient(
      160deg,
      ${colors.primary || "#2196f3"}15,
      transparent
    );
    opacity: 0.1;
    z-index: 1;
  }
`;

const ContentWrapper = styled(Box)`
  position: relative;
  z-index: 2;
`;

export default function AboutUs() {
  return (
    <AboutUsContainer id="about">
      <Box sx={{ width: "100%", py: { xs: 6, md: 10 } }}>
        <Container maxWidth="lg">
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      lg: "2.4rem",
                      md: "1.7rem",
                      sm: "1.5rem",
                      xs: "1.3rem",
                    },
                    fontWeight: 700,
                    color: colors.fontColor,
                    mb: { xs: 2, md: 0 },
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: -16,
                      left: 0,
                      width: 60,
                      height: 4,
                      backgroundColor: colors.primary || "#2196f3",
                      display: { xs: "none", md: "block" },
                    },
                  }}
                >
                  About Us
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: colors.fontGray,
                    mb: 4,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    lineHeight: 1.8,
                  }}
                >
                  At Webtimate, we're dedicated to revolutionizing your frontend
                  development experience. We understand the challenges faced by
                  businesses in today's competitive landscape, from talent
                  scarcity to the need for rapid scaling. That's why we're here
                  to streamline your frontend development with immediate
                  onboarding and zero hassle.
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: colors.fontGray,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    lineHeight: 1.8,
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      color: colors.primary || "#2196f3",
                      fontWeight: 600,
                      display: "block",
                      mb: 1,
                    }}
                  >
                    We Supercharge Your Frontend Development with Top-tier
                    Expertise
                  </Box>
                  Our team of dedicated developers is your ticket to offloading
                  frontend development with absolute confidence. Whether it's
                  ReactJS or React Native, our skilled professionals excel at
                  creating secure, scalable, and dynamic web and mobile apps
                  that perfectly align with your vision. With Webtimate handling
                  the frontend, you can focus on what you do best.
                </Typography>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Container>
      </Box>
    </AboutUsContainer>
  );
}
