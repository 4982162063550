import Home from "./views/Home";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import Thankyou from "./views/Thankyou";
import ReactGA from "react-ga";
import { default as ReactGA4 } from "react-ga4";
import usePageTracking from "./utils/usePageTracking";
import { useEffect } from "react";
ReactGA.initialize("UA-60602511-1");
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA4.initialize("G-NX0EC025XS");
// ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
});

function App() {
  usePageTracking();
  // RB2B's
  useEffect(() => {
    // Your custom JavaScript snippet
    (function () {
      var reb2b = (window.reb2b = window.reb2b || []);
      if (reb2b.invoked) return;
      reb2b.invoked = true;
      reb2b.methods = ["identify", "collect"];
      reb2b.factory = function (method) {
        return function () {
          var args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          reb2b.push(args);
          return reb2b;
        };
      };
      for (var i = 0; i < reb2b.methods.length; i++) {
        var key = reb2b.methods[i];
        reb2b[key] = reb2b.factory(key);
      }
      reb2b.load = function (key) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" +
          key +
          "/reb2b.js.gz";
        var first = document.getElementsByTagName("script")[0];
        first.parentNode.insertBefore(script, first);
      };
      reb2b.SNIPPET_VERSION = "1.0.1";
      reb2b.load("EN4M0HKZKGOM");
    })(); // Directly calling the IIFE here
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/* <div
        style={{
          overflowX: "hidden",
        }}
      > */}
      {/* <Home /> */}
      {/* </div> */}
      {/* <Router> */}
      <Routes>
        <Route index element={<Home />} />
        <Route path="thankyou" element={<Thankyou />} />
      </Routes>
      {/* </Router> */}
    </ThemeProvider>
  );
}

export default App;
