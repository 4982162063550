import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-scroll";
import { colors } from "../utils/colors";
import { trackCallClick } from "../utils/misc";
import { BookingURL } from "../utils/constant";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleBookaCall = (event) => {
    trackCallClick();
  };

  return (
    <AppBar position="sticky" style={{ backgroundColor: colors.primary }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* responsive logo here   */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: colors.white }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              <Link to="staff" smooth={true} offset={-100} duration={3000}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Staff Augmentation</Typography>
                </MenuItem>
              </Link>
              <Link to="tech-stack" smooth={true} duration={3000}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Technical Staff</Typography>
                </MenuItem>
              </Link>
              <Link to="faq" smooth={true} duration={3000}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">FAQ</Typography>
                </MenuItem>
              </Link>
              <Link to="about" smooth={true} duration={3000}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">About</Typography>
                </MenuItem>
              </Link>
              <Link to="team" smooth={true} duration={3000}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Team</Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          {/*  logo here */}
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img
              src={require("../assets/logo_webtimate.png")}
              alt="logo"
              height={50}
              width={200}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "450px",
              }}
            >
              <Link to="staff" smooth={true} duration={4000}>
                <Typography
                  sx={{
                    my: 2,
                    marginLeft: 5,
                    display: "block",
                    cursor: "pointer",
                  }}
                  variant="subtitle2"
                >
                  Staff Augmentation
                </Typography>
              </Link>
              <Link to="tech-stack" smooth={true} duration={4000}>
                <Typography
                  variant="subtitle2"
                  sx={{ my: 2, display: "block", cursor: "pointer" }}
                >
                  Technical Staff
                </Typography>
              </Link>
              <Link to="faq" smooth={true} duration={7000}>
                <Typography
                  variant="subtitle2"
                  sx={{ my: 2, display: "block", cursor: "pointer" }}
                >
                  FAQ
                </Typography>
              </Link>
              <Link to="about" smooth={true} duration={7000}>
                <Typography
                  variant="subtitle2"
                  sx={{ my: 2, display: "block", cursor: "pointer" }}
                >
                  About
                </Typography>
              </Link>
              <Link to="team" smooth={true} duration={7000}>
                <Typography
                  variant="subtitle2"
                  sx={{ my: 2, display: "block", cursor: "pointer" }}
                >
                  Team
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Button
              variant="outlined"
              sx={{
                color: `${colors.buttonColor} !important`,
              }}
              href={BookingURL}
              target="_blank"
              onClick={handleBookaCall}
            >
              BOOK A CALL
            </Button>
            {/* <Link to="pricing" smooth={true} duration={3000}>
              <Button
                variant="contained"
                style={{
                  marginLeft: "10px",
                  backgroundColor: colors.buttonColor,
                }}
              >
                PLAN & PRICING
              </Button>
            </Link> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
